import React from "react";
import Navbar from "../components/navbar";
import HeroSection from "../components/heroSection";
import Skills from "../components/SkillCards";
import Projects from "./Projects";
import Contact from "../components/ContactSection";
// import CustomerReview from "../components/CustomerReview";
import ProgressBar from "../components/ProgressBar";
import Footer from "../components/Footer";

const Home = () => {
	return (
		<>
			<ProgressBar />
			<Navbar />
			<HeroSection />
			<Skills />
			{/* <Education /> */}
			<Projects />
			{/* <CustomerReview /> */}
			<Contact />
			<Footer />
		</>
	);
};

export default Home;
